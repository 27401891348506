//background color
$transparent-bg: #00000000;
$idle-light-bg: #fdfdfd;
$active-light-bg: #017ea7;
$hover-bg: #00b4c5;
$light-bg: #fff;
$dark-bg: #0a252e;
$focus-bg: #efefef;
$idle-dark-bg: #34495d;
$active-dark-bg: #1e3142;
$idle-view-profile-btn-bg: #fcfcfc;
$active-view-profile-btn-bg: #04a2ae;
$tooltips-bg: #4ac5f3;
$logout-btn-bg: #25cdd9;
$thead-bg: #afc3ca;
$scroll-bg: #0a6688;
$expansion-bg: #edf3f5;

//border color
$light-border: #017ea7;
$dark-border: #152330;
$dark-border-2: #09242e;
$hover-border: #00b4c5;
$text-field-border: #979797;
$view-profile-btn-border: #04a2ae;
$show-more-border: #707070;
$modal-border: #25cdd9;
$divider-color: #d8d8d8;
$expansion-border: #a3b8bf;

//text color
$dark-text-2: #0a252e;
$btn-text: #017ea7;
$light-text: #fff;
$view-profile-btn-text: #04a2ae;
$show-more-text: #707070;
$date-text: #007fab;
$placeholder-text: #a8bec5;
$link-text: #05cdd9;

//new-design-color
$primary-dark: #1e3142;
$primary-light: #e1e8eb;
$secondary-dark: #017ea7;
$secondary-light: #25cdd9;
$error-color: #d92525;
$success-color: #4bbf2b;
$warning-color: #e8c100;
$hover-color: #abc0c7;
$dark-text: #09242e;
$light-text: #fff;
$disabled-color: #afc3ca;
$selected-color: #d3f2f2;
