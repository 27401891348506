@import './assets/styles/colors';

@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  &::-webkit-scrollbar-thumb {
    border-radius: 3.5;
    background: $active-light-bg;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(175, 195, 202, 0.5);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

* {
  font-family: Roboto;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 1120px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

@mixin flex-position($positionX: center, $positionY: center) {
  @extend .flex;
  justify-content: $positionX;
  align-content: $positionY;
  align-items: $positionY;
}

@mixin flex-column-position($positionX: center, $positionY: center) {
  @extend .flex-column;
  align-content: $positionX;
  align-items: $positionX;
  justify-content: $positionY;
}

@mixin button-style(
  $idleBg: $active-light-bg,
  $idleBorder: $light-border,
  $idleColor: $light-text,
  $activeBg: $hover-bg,
  $activeBorder: $hover-border,
  $activeColor: $light-text,
  $buttonWidth: 192px,
  $buttonHeight: 40px,
  $borderRadius: 30px
) {
  color: $idleColor;
  background: $idleBg;
  border: $idleBorder 2px solid;
  font-weight: normal !important;
  border-radius: $borderRadius !important;
  font-family: roboto;
  width: $buttonWidth;
  height: $buttonHeight;
  outline: none;
  cursor: pointer;
  &:active,
  &:hover {
    background: $activeBg;
    color: $activeColor;
    border: $activeBorder 2px solid;
    font-weight: normal !important;
    g[fill='#0A6688'] {
      fill: $activeColor;
    }
  }
}

@mixin text-style(
  $fontSize: 32px,
  $textColor: $dark-text-2,
  $fontWeight: bold,
  $lineHeight: 1,
  $letterSpacing: 0.5px
) {
  font-size: $fontSize;
  color: $textColor;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ui.button:focus {
  background-color: $active-light-bg;
  color: $light-text;
  background-image: '' !important;
  -webkit-box-shadow: '' !important;
  box-shadow: '' !important;
}

@mixin textfield-color(
  $idleBg: #1e3142,
  $idleBorder: #25cdd9,
  $idleColor: #919ba3,
  $activeBg: #223444,
  $activeBorder: #25cdd9,
  $activeColor: white
) {
  background: $idleBg;
  border: $idleBorder 1px solid;
  color: $activeColor;
  font-family: roboto;
  &:focus {
    background: $activeBg;
    border: $activeBorder 1px solid;
    color: $activeColor;
    outline: none;
  }
}

@mixin text-link($color: $link-text) {
  text-decoration: underline;
  color: $color;
  cursor: pointer;
}

.textfield-error {
  input {
    border: #fb2828 1px solid !important;
    &::placeholder {
      color: #fb2828 !important;
    }
  }
}

.error {
  color: #fb2828;
}

.DayPicker-wrapper {
  width: 310.5px;
  .DayPicker-Month {
    width: 100%;
  }
}

.ui.table thead th {
  padding: 1px 6px;
}

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown,
.ui.upward.selection.dropdown.visible {
  ::-webkit-scrollbar-thumb {
    border-radius: 3.5;
    background: $active-light-bg;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(175, 195, 202, 0.5);
  }
  border-color: $active-dark-bg;
  border-radius: 0px;
}
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown:hover {
  border-color: $active-dark-bg;
  border-radius: 0px;
}
.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: $dark-text;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: $thead-bg;
  color: $dark-text;
}

.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  opacity: 1;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 0.87;
}

i.icon.dropdown:before {
  font-size: 15px;
}

.ui.secondary.pointing.menu .item {
  border-color: $transparent-bg !important;
  border-bottom-width: 4px;
  width: 150px;
  @include flex-position;
  @include text-style(14px, $dark-text-2, bold);
  padding: 12px 0px;
  margin: 0 20px;
  &:hover {
    border-color: #afc3ca !important;
  }
}

.ui.secondary.pointing.menu .active.item {
  border-color: #017ea7 !important;
}

.ui.segment {
  border-radius: 0px;
  padding: 0px;
  border: none;
}

.ui.button:disabled {
  background-color: $disabled-color !important;
  color: $light-text !important;
  border-color: $disabled-color !important;
  opacity: 1 !important;
}
