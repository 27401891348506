@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }
  body::-webkit-scrollbar-thumb {
    border-radius: 3.5;
    background: #017ea7; }
  body::-webkit-scrollbar {
    width: 8px; }
  body::-webkit-scrollbar-track {
    background: rgba(175, 195, 202, 0.5); }
  body::-webkit-scrollbar-button {
    display: none; }

* {
  font-family: Roboto;
  box-sizing: border-box; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.container {
  width: 1120px;
  margin: 0 auto; }

.flex, .flex-column, .ui.secondary.pointing.menu .item {
  display: flex; }

.flex-column {
  flex-direction: column; }

.ui.button:focus {
  background-color: #017ea7;
  color: #fff;
  background-image: '' !important;
  box-shadow: '' !important; }

.textfield-error input {
  border: #fb2828 1px solid !important; }
  .textfield-error input::-webkit-input-placeholder {
    color: #fb2828 !important; }
  .textfield-error input::-ms-input-placeholder {
    color: #fb2828 !important; }
  .textfield-error input::placeholder {
    color: #fb2828 !important; }

.error {
  color: #fb2828; }

.DayPicker-wrapper {
  width: 310.5px; }
  .DayPicker-wrapper .DayPicker-Month {
    width: 100%; }

.ui.table thead th {
  padding: 1px 6px; }

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown,
.ui.upward.selection.dropdown.visible {
  border-color: #1e3142;
  border-radius: 0px; }
  .ui.selection.active.dropdown .menu ::-webkit-scrollbar-thumb,
  .ui.selection.active.dropdown ::-webkit-scrollbar-thumb,
  .ui.upward.selection.dropdown.visible ::-webkit-scrollbar-thumb {
    border-radius: 3.5;
    background: #017ea7; }
  .ui.selection.active.dropdown .menu ::-webkit-scrollbar,
  .ui.selection.active.dropdown ::-webkit-scrollbar,
  .ui.upward.selection.dropdown.visible ::-webkit-scrollbar {
    width: 8px; }
  .ui.selection.active.dropdown .menu ::-webkit-scrollbar-track,
  .ui.selection.active.dropdown ::-webkit-scrollbar-track,
  .ui.upward.selection.dropdown.visible ::-webkit-scrollbar-track {
    background: rgba(175, 195, 202, 0.5); }

.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown:hover {
  border-color: #1e3142;
  border-radius: 0px; }

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: #09242e; }

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #afc3ca;
  color: #09242e; }

.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  opacity: 1; }

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 0.87; }

i.icon.dropdown:before {
  font-size: 15px; }

.ui.secondary.pointing.menu .item {
  border-color: #00000000 !important;
  border-bottom-width: 4px;
  width: 150px;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: #0a252e;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 12px 0px;
  margin: 0 20px; }
  .ui.secondary.pointing.menu .item:hover {
    border-color: #afc3ca !important; }

.ui.secondary.pointing.menu .active.item {
  border-color: #017ea7 !important; }

.ui.segment {
  border-radius: 0px;
  padding: 0px;
  border: none; }

.ui.button:disabled {
  background-color: #afc3ca !important;
  color: #fff !important;
  border-color: #afc3ca !important;
  opacity: 1 !important; }

